import React from "react"
import { graphql } from "gatsby"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"
import styled from "styled-components"
import device from "../components/Theme/devices"
import { GridCell, GridLayout } from "../components/GridLayout"
import { SectionTitle } from "../components/Texts"
import { BlogThumbnailSet } from "../layout/BlogThumbnailSet"
import { BlogPostThumbnail } from "../types/blogPost"
import { Favicon } from "../components/Favicon"

const Container = styled(GridLayout).attrs({
  greenish: true,
})``

const Title = styled(GridCell).attrs({
  row: 1,
  tabletRow: 1,
  column: "3 / 10",
  tabletCol: "2 / 7",
  leftMargin: "phone-only",
  rightMargin: "phone-only",
  as: SectionTitle,
  forwardedAs: "h1",
})`
  padding-top: 30px;
  @media (${device.desktop}) {
    margin-top: 250px;
  }
  margin-top: 0;
  margin-bottom: ${50 - 21}px;
  @media (${device.desktop}) {
    margin-bottom: ${135 - 21}px;
  }
`

const Blog: React.FC<{ data: PageQueryType }> = ({
  data: { blogData, posts },
}) => {
  return (
    <main>
      <Container>
        <Title>{blogData.title}</Title>
      </Container>
      <BlogThumbnailSet
        greenish
        posts={posts?.edges?.map(({ node }) => node) || []}
      />
      <HelmetDatoCms seo={blogData.seoMetaTags} />
      <Favicon />
    </main>
  )
}

export default Blog

type PageQueryType = {
  blogData: {
    title: string
    seoMetaTags: any
  }

  posts?: {
    edges?: {
      node: BlogPostThumbnail
    }[]
  }
}

export const query = graphql`
  query blogData {
    blogData: datoCmsBlog {
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    posts: allDatoCmsBlogPost(
      sort: { fields: [meta___publishedAt], order: [DESC] }
    ) {
      edges {
        node {
          ...BlogPostThumbnail
        }
      }
    }
  }
`
